import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Promo from '../../Images/1.png'
import instagram from '../../Images/instagram.png'
import bumpVapeSimple1 from '../../Images/6.png'
import bumpVapeSimple2 from '../../Images/7.png'
import bumpVapeSimple3 from '../../Images/5.png'
import BackGroundMain from '../../Images/AboutBG1.png'
import Arrow2 from '../../Images/Arrow2.svg'
import HealtyImage from '../../Images/HealtyImage.jpg'
import warning from '../../Images/warning.png'

export default function HomePage({ navigateTo }) {
    const [showOne, setshowOne] = useState(true);
    const [showTwo, setshowTwo] = useState(false);
    const [showThree, setshowThree] = useState(false);

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Experience the smoothest, most flavorful vape... | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:image" content="%PUBLIC_URL%/Banner.png" />
                <meta property="og:url" content="https://www.thebumpvape.com" data-react-helmet="true" />
                <meta name="description" content="Looking for high-quality disposable vaping products? Look no further than Bump Vapes! Our wide variety of disposable vape devices are designed to provide the ultimate vaping experience, no matter your preferences or needs. From fruity flavors to classic tobacco blends, we have something for everyone. And with our long-lasting batteries, you can enjoy your vape for longer without having to constantly recharge. So why settle for less? Choose Bump Vapes for the best disposable vaping products on the market." data-react-helmet="true" />
                <meta property="og:title" content="Experience the smoothest, most flavorful vape... | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Looking for high-quality disposable vaping products? Look no further than Bump Vapes! Our wide variety of disposable vape devices are designed to provide the ultimate vaping experience, no matter your preferences or needs. From fruity flavors to classic tobacco blends, we have something for everyone. And with our long-lasting batteries, you can enjoy your vape for longer without having to constantly recharge. So why settle for less? Choose Bump Vapes for the best disposable vaping products on the market." data-react-helmet="true" />
                <meta property="og:site_name" content="Experience the smoothest, most flavorful vape... | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, disposable, vape bump, e-juice" data-react-helmet="true"></meta>
            </Helmet>

            {/* MainBanner */}
            <div className="MainBanner">
                <div className="HomePageText">
                    <h1>THE VAPE GAME</h1>
                    <h2>Looking for high-quality disposable vaping products? Look no further than Bump Vapes! Our wide variety of disposable vape devices are designed to provide the ultimate vaping experience, no matter your preferences or needs. From fruity flavors to classic tobacco blends, we have something for everyone. And with our long-lasting batteries, you can enjoy your vape for longer without having to constantly recharge. So why settle for less? Choose Bump Vapes for the best disposable vaping products on the market.</h2>
                    <button onClick={() => navigateTo('about')}>Learn More</button>
                </div>
                <div className="HomePageVideo">
                    {/* <video controls autoPlay loop playsInline >
                    <video controls autoPlay loop muted >
                        <source src={Smoke2} type="video/mp4"></source>
                    </video> */}
                    <img src={BackGroundMain} alt="" />
                </div>
            </div>
            {/* Products */}
            <div className="Products">
                <div className="ProductsTop2">
                    <div onClick={() => {
                        setshowOne(true)
                        setshowTwo(false)
                        setshowThree(false)
                    }} style={showOne ? { "backgroundColor": "white" } : null} className="ProductsOne ProductsBox">
                        <img src={bumpVapeSimple1} alt="" />
                        <h1>INFINTY</h1>
                    </div>
                    <div onClick={() => {
                        setshowOne(false)
                        setshowTwo(true)
                        setshowThree(false)
                    }} style={showTwo ? { "backgroundColor": "white" } : null} className="ProductsSecond ProductsBox">
                        <img src={bumpVapeSimple2} alt="" />
                        <h1>GENESIS</h1>
                    </div>
                    <div onClick={() => {
                        setshowOne(false)
                        setshowTwo(false)
                        setshowThree(true)
                    }} style={showThree ? { "backgroundColor": "white" } : null} className="ProductsThird ProductsBox">
                        <img src={bumpVapeSimple3} alt="" />
                        <h1>PRO X</h1>
                    </div>
                </div>
                <div className="ProductsBottom">
                    {showOne ?
                        <div className="ProductsBottomOne">
                            <div className="ProductsLeft">
                                <h2>THE PRODUCT</h2>
                                <h1 style={{ "color": "red" }}>INFINITY</h1>
                                <h4>THE COMFORT AND THE FLAVORS</h4>
                                <p>The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.</p>
                                <button onClick={() => navigateTo('products/infinity')}>Learn More</button>
                                <img src={warning} alt="" />
                            </div>
                            <div className="ProductsRight">
                                <img src={bumpVapeSimple1} alt="" />
                                {/* <button>Learn More</button> */}
                            </div>
                        </div>
                        :
                        null
                    }

                    {showTwo ?
                        <div className="ProductsBottomOne">
                            <div className="ProductsLeft">
                                <h2>THE PRODUCT</h2>
                                <h1 style={{ "color": "blue" }}>GENESIS</h1>
                                <h4>THE COMFORT AND THE FLAVORS</h4>
                                <p>The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.</p>
                                <button onClick={() => navigateTo('products/genesis')}>Learn More</button>
                                <img src={warning} alt="" />
                            </div>
                            <div className="ProductsRight">
                                <img src={bumpVapeSimple2} alt="" />
                                {/* <button>Learn More</button> */}
                            </div>
                        </div>
                        :
                        null
                    }
                    {showThree ?
                        <div className="ProductsBottomOne">
                            <div className="ProductsLeft">
                                <h2>THE PRODUCT</h2>
                                <h1 style={{ "color": "orange" }}>PRO X</h1>
                                <h4>THE COMFORT AND THE FLAVORS</h4>
                                <p>The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.</p>
                                <button onClick={() => navigateTo('products/prox')}>Learn More</button>
                                <img src={warning} alt="" />
                            </div>
                            <div className="ProductsRight">
                                <img src={bumpVapeSimple3} alt="" />
                                {/* <button>Learn More</button> */}
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
            {/* VapesProducts */}
            <div className="VapesProductsMain">
                <div className="VapesProductsMainHeader">
                    <h1>BUMP Products</h1>
                    <p onClick={() => navigateTo('products')}>Learn More</p>
                </div>
                <div className="VapesProductsCategory">
                    <div className="VapesProductsCategoryHeader">
                        <div className="VapesProductsCategoryHeaderLeft">
                            {/* <img src={bumpVapeSimple2} alt="" /> */}
                            <h1>Infinity</h1>
                            <div className="VapesProductsCategoryHeaderLeftArrows">
                                <img className='arrow-left' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.VapesProductsCategoryList1`), x;
                                    if ($('.arrow-left').hasClass("arrow-left")) {
                                        x = ((box.width() / 2)) - box.scrollLeft();
                                        box.animate({
                                            scrollLeft: -x,
                                        })
                                    }
                                }} />
                                <img className='arrow-right' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.VapesProductsCategoryList1`), x;
                                    if ($('.arrow-right').hasClass("arrow-right")) {
                                        x = ((box.width() / 2)) + box.scrollLeft();
                                        box.animate({
                                            scrollLeft: x,
                                        })
                                    }
                                }} />
                            </div>
                        </div>
                        <div className="VapesProductsCategoryHeaderRight">
                            <h3 onClick={() => navigateTo('products/infinity')}>VIEW ALL</h3>
                            {/* <h3 onClick={() => navigate('/casino/roulette?sort=popular')}>VIEW ALL</h3> */}
                        </div>
                    </div>
                    <div className="VapesProductsCategoryList1">
                        {[...Array(10)].map((A, index) =>
                            <div key={index} className="VapesProductsCategoryBox">
                                <img className='PlayGame' src={bumpVapeSimple1} alt="" />
                                <h3>Water Melon Mint</h3>
                            </div>
                        )}
                        <div className="CasinoGameProvidersListShadow"></div>

                    </div>

                </div>
                <div className="VapesProductsCategory">
                    <div className="VapesProductsCategoryHeader">
                        <div className="VapesProductsCategoryHeaderLeft">
                            {/* <img src={bumpVapeSimple2} alt="" /> */}
                            <h1>Genesis</h1>
                            <div className="VapesProductsCategoryHeaderLeftArrows">
                                <img className='arrow-left' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.VapesProductsCategoryList2`), x;
                                    if ($('.arrow-left').hasClass("arrow-left")) {
                                        x = ((box.width() / 2)) - box.scrollLeft();
                                        box.animate({
                                            scrollLeft: -x,
                                        })
                                    }
                                }} />
                                <img className='arrow-right' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.VapesProductsCategoryList2`), x;
                                    if ($('.arrow-right').hasClass("arrow-right")) {
                                        x = ((box.width() / 2)) + box.scrollLeft();
                                        box.animate({
                                            scrollLeft: x,
                                        })
                                    }
                                }} />
                            </div>
                        </div>
                        <div className="VapesProductsCategoryHeaderRight">
                            <h3 onClick={() => navigateTo('products/genesis')}>VIEW ALL</h3>
                            {/* <h3 onClick={() => navigate('/casino/roulette?sort=popular')}>VIEW ALL</h3> */}
                        </div>
                    </div>
                    <div className="VapesProductsCategoryList2">
                        {[...Array(10)].map((A, index) =>
                            <div key={index} className="VapesProductsCategoryBox">
                                <img className='PlayGame' src={bumpVapeSimple2} alt="" />
                                <h3>Water Melon Mint</h3>
                            </div>
                        )}
                        <div className="CasinoGameProvidersListShadow"></div>

                    </div>

                </div>
                <div className="VapesProductsCategory">
                    <div className="VapesProductsCategoryHeader">
                        <div className="VapesProductsCategoryHeaderLeft">
                            {/* <img src={bumpVapeSimple2} alt="" /> */}
                            <h1>PRO X</h1>
                            <div className="VapesProductsCategoryHeaderLeftArrows">
                                <img className='arrow-left' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.VapesProductsCategoryList3`), x;
                                    if ($('.arrow-left').hasClass("arrow-left")) {
                                        x = ((box.width() / 2)) - box.scrollLeft();
                                        box.animate({
                                            scrollLeft: -x,
                                        })
                                    }
                                }} />
                                <img className='arrow-right' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.VapesProductsCategoryList3`), x;
                                    if ($('.arrow-right').hasClass("arrow-right")) {
                                        x = ((box.width() / 2)) + box.scrollLeft();
                                        box.animate({
                                            scrollLeft: x,
                                        })
                                    }
                                }} />
                            </div>
                        </div>
                        <div className="VapesProductsCategoryHeaderRight">
                            <h3 onClick={() => navigateTo('products/prox')}>VIEW ALL</h3>
                            {/* <h3 onClick={() => navigate('/casino/roulette?sort=popular')}>VIEW ALL</h3> */}
                        </div>
                    </div>
                    <div className="VapesProductsCategoryList3">
                        {[...Array(10)].map((A, index) =>
                            <div key={index} className="VapesProductsCategoryBox">
                                <img className='PlayGame' src={bumpVapeSimple3} alt="" />
                                <h3>Water Melon Mint</h3>
                            </div>
                        )}
                        <div className="CasinoGameProvidersListShadow"></div>

                    </div>

                </div>
            </div>
            {/* Healthy */}
            <div className="Healthy">
                <div className="HealthyHeader">
                    <h1>Go Byond</h1>
                </div>
                <div className="HealthyMain">
                    <div className="HealthyLeft">
                        <img src={HealtyImage} alt="" />
                    </div>
                    <div className="HealthyRight">
                        <h1>The Teenagers Program</h1>
                        <h3>Additionally, Bump Vape actively collaborates with regulatory bodies and educational institutions to promote awareness and discourage underage smoking. We invest in comprehensive campaigns, providing accurate information about the potential risks associated with smoking and vaping. By partnering with schools and organizations, we strive to empower teenagers with the knowledge and tools they need to make informed choices regarding their health.</h3>
                        <h4>Learn More</h4>
                    </div>
                </div>
            </div>
            {/* TheVape */}
            <div className="TheVape">
                <div className="TheVapeHeader">
                    <h1>Product <b>Benefits</b></h1>
                </div>
                <div className="TheVapeMain">
                    <div className="TheVapeMainLeft">
                        <div className="TheVapeMainBox">
                            <img src={instagram} alt="" />
                            <div className="TheVapeMainBoxRow">
                                <div className="TheVapeMainBoxRowLine"></div>
                                <div className="TheVapeMainBoxRowCircle"></div>
                            </div>
                            <h2>Best Battery</h2>
                            <h3>Delivering a ruch satisfying taste</h3>
                        </div>
                        <div className="TheVapeMainBox">
                            <img src={instagram} alt="" />
                            <div className="TheVapeMainBoxRow">
                                <div className="TheVapeMainBoxRowLine"></div>
                                <div className="TheVapeMainBoxRowCircle"></div>
                            </div>
                            <h2>Best Battery</h2>
                            <h3>Delivering a ruch satisfying taste</h3>
                        </div>
                    </div>
                    <div className="TheVapeMainMiddle">
                        <img src={bumpVapeSimple1} alt="" />
                    </div>
                    <div className="TheVapeMainRight">
                        <div className="TheVapeMainBox">
                            <img src={instagram} alt="" />
                            <div className="TheVapeMainBoxRow">
                                <div className="TheVapeMainBoxRowCircle"></div>
                                <div className="TheVapeMainBoxRowLine"></div>
                            </div>
                            <h2>Best Battery</h2>
                            <h3>Delivering a ruch satisfying taste</h3>
                        </div>
                        <div className="TheVapeMainBox">
                            <img src={instagram} alt="" />
                            <div className="TheVapeMainBoxRow">
                                <div className="TheVapeMainBoxRowCircle"></div>
                                <div className="TheVapeMainBoxRowLine"></div>
                            </div>
                            <h2>Best Battery</h2>
                            <h3>Delivering a ruch satisfying taste</h3>
                        </div>
                    </div>


                </div>
            </div>
            {/* Wholesale */}
            <div className="Wholesale">
                <h1>BUMP Wholesale</h1>
                <h2>If you are intrested in becoming a disributo for Bump Vape, Let's get it.</h2>
                <button onClick={() => {
                    // headerDesing()
                    navigateTo("contact")
                }}>START NOW</button>
            </div>
        </div>
    )
}

