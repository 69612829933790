import React, { useState } from 'react'
import { useEffect } from 'react';
import './Products.scss'
import $ from 'jquery';
import { Helmet } from "react-helmet";
// images
import bumpVapeSimple1 from '../../Images/6.png'
import bumpVapeSimple2 from '../../Images/5.png'
import bumpVapeSimple3 from '../../Images/7.png'
import watermelon from '../../Images/watermelon.jpeg'
import warning from '../../Images/warning.png'
import FlavorsMains from '../../Images/FlavorsMains.jpeg'
import BGFLAVORS from '../../Images/BGFLAVORS.jpg'
import BGFLAVORS2 from '../../Images/BGFLAVORS2.webp'
import BGFLAVORS3 from '../../Images/BGFLAVORS3.jpeg'
import TypeC from '../../Images/TypeC.png'
import TypeCBG from '../../Images/TypeCBG.jpeg'
import TypeClogo from '../../Images/TypeClogo.webp'
import TypeCmain from '../../Images/TypeCmain.png'
import Arrow2 from '../../Images/Arrow2.svg'
import { useLocation } from 'react-router-dom';


export default function Products({ navigateTo }) {
    const location = useLocation().pathname.split('/')[0]
    const [SpecificProduct, setSpecificProduct] = useState({});

    const [Products, setProducts] = useState([
        {
            name: "INFINITY",
            id: "infinity",
            description: "THE COMFORT AND THE FLAVORS",
            h1text: 'ALL DAY LONG',
            h2text: 'As long as possible',
            info: "The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.",
            imageBG: BGFLAVORS,
            imageVape: bumpVapeSimple1,
            h1color: "red",
            url: "products/infinity"
        },
        {
            name: "GENESIS",
            id: "genesis",
            description: "THE COMFORT AND THE FLAVORS",
            h1text: 'CONTRACT...',
            h2text: 'But Compact',
            info: "The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.",
            imageBG: BGFLAVORS2,
            imageVape: bumpVapeSimple2,
            h1color: "yellow",
            url: "products/genesis"
        },
        {
            name: "PRO X",
            id: "prox",
            description: "THE COMFORT AND THE FLAVORS",
            h1text: 'AWW !!',
            h2text: 'Fresh',
            info: "The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.",
            imageBG: BGFLAVORS3,
            imageVape: bumpVapeSimple3,
            h1color: "white",
            url: "products/prox"
        }
    ]);


    useEffect(() => {
        const productA = Products.find((a) => a.id == window.location.pathname.split('/')[2])
        setSpecificProduct(productA)
        
    }, [window.location.pathname.split('/')[2]]);
    
    return (
        <div className="Products">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Bump {useLocation().pathname.split('/')[2]} | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content={`https://www.thebumpvape.com/products/${useLocation().pathname.split('/')[2]}`} data-react-helmet="true" />
                <meta name="description" content="Innovative, sleek design. Wide range of flavors. Compact and portable. Premium-quality vapor production. Long-lasting battery life. Easy-to-use functionality. Satisfying nicotine hit. Stylish and trendy. Diverse nicotine strength options. Satisfaction guaranteed." data-react-helmet="true" />
                <meta property="og:title" content={`Bump ${useLocation().pathname.split('/')[2]} | The Bump Vape`} data-react-helmet="true" />
                <meta property="og:description" content="Innovative, sleek design. Wide range of flavors. Compact and portable. Premium-quality vapor production. Long-lasting battery life. Easy-to-use functionality. Satisfying nicotine hit. Stylish and trendy. Diverse nicotine strength options. Satisfaction guaranteed." data-react-helmet="true" />
                <meta property="og:site_name" content={`Bump ${useLocation().pathname.split('/')[2]} | The Bump Vape`} data-react-helmet="true" />
                <meta name="keywords" content={`BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump ${useLocation().pathname.split('/')[2]}`} data-react-helmet="true"></meta>
            </Helmet>

            <div className="ProductsTop">
                <img className='ProductsTopBG' src={SpecificProduct.imageBG} alt="" />
                <img className='ProductsTopVape' src={SpecificProduct.imageVape} alt="" />
                <div className="ProductsTopInfo">
                    <h1 style={{ "color": SpecificProduct.h1color }}>{SpecificProduct.h1text}</h1>
                    <h2 style={{ "color": SpecificProduct.h1color, "backdropFilter": "blur(10px)", "backgroundColor": "#ffffff22" }}>{SpecificProduct.h2text}</h2>
                </div>
            </div>
            <div className="ProductsMainOne">
                <div className="ProductsLeft">
                    <h2>THE PRODUCT</h2>
                    <h1 style={{ "color": "red" }}>{SpecificProduct.name}</h1>
                    <h4>{SpecificProduct.description}</h4>
                    <p>{SpecificProduct.info}</p>
                    <img src={warning} alt="" />
                </div>
                <div className="ProductsRight">
                    <img src={bumpVapeSimple1} alt="" />
                    {/* <button>Learn More</button> */}
                </div>
            </div>
            <div className="ProductsFlavors">
                <div className="ProductsFlavorsHeader">
                    <h1>Flavors</h1>
                </div>
                <div className="ProductsFlavorsDesc">
                    <h1>WATER MELONE</h1>
                    <h2>Flavor Details</h2>
                    <h3>Mixed berries including raspberry, blueberry and blackberry create the perfect combination of sweet and tart flavors.</h3>
                    <img src={watermelon} alt="" />
                </div>
                <div className="VapesProductsCategory">
                    <div className="VapesProductsCategoryHeader">
                        <div className="VapesProductsCategoryHeaderLeft">
                            {/* <img src={bumpVapeSimple2} alt="" /> */}
                            <h1>{SpecificProduct.name}</h1>
                            <div className="VapesProductsCategoryHeaderLeftArrows">
                                <img className='arrow-left' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.ProductsFlavorsMain`), x;
                                    if ($('.arrow-left').hasClass("arrow-left")) {
                                        x = ((box.width() / 2)) - box.scrollLeft();
                                        box.animate({
                                            scrollLeft: -x,
                                        })
                                    }
                                }} />
                                <img className='arrow-right' src={Arrow2} alt="" onClick={() => {
                                    var box = $(`.ProductsFlavorsMain`), x;
                                    if ($('.arrow-right').hasClass("arrow-right")) {
                                        x = ((box.width() / 2)) + box.scrollLeft();
                                        box.animate({
                                            scrollLeft: x,
                                        })
                                    }
                                }} />
                            </div>
                        </div>
                        <div className="VapesProductsCategoryHeaderRight">
                            {/* <h3 onClick={() => null}>VIEW ALL</h3> */}
                            {/* <h3 onClick={() => navigate('/casino/roulette?sort=popular')}>VIEW ALL</h3> */}
                        </div>
                    </div>
                    <div className="ProductsFlavorsMain">
                        {[...Array(10)].map((A, index) =>
                            <div key={index} className="ProductsFlavorsMainOne">
                                <div className="ProductsFlavorsMainOneBG">
                                    <img src={watermelon} alt="" />
                                    <div className="ProductsFlavorsMainOneBGBottom">
                                        <h4>WATER MELON</h4>
                                    </div>
                                </div>
                                <img className='SpecificProduct' src={SpecificProduct.imageVape} alt="" />
                                {/* <h3>Water Melon Mint</h3> */}
                            </div>
                        )}
                        <div className="CasinoGameProvidersListShadow"></div>

                    </div>

                </div>

            </div>
            <div className="TypeC">
                {/* <img src={TypeCmain} alt="" /> */}
                <img className='TypeC1' src={TypeC} alt="" />
                <img className='TypeC2' src={TypeCBG} alt="" />
                <img className='TypeC3' src={bumpVapeSimple1} alt="" />
                <img className='TypeC4' src={bumpVapeSimple1} alt="" />
                <img className='TypeC5' src={TypeClogo} alt="" />
                <h1>The charging option is convenient and easy with Type C </h1>
            </div>
            <div className="ProductsMore">
                <h1>See More Products</h1>
                <div className="ProductsMoreArray">

                    {Products.filter((a => a.id != window.location.pathname.split('/')[2])).map((A, index) =>
                        <div className="ProductsTop2">
                            <h4>{A.name}</h4>
                            <img className='ProductsTopBG' src={A.imageBG} alt="" />
                            <img className='ProductsTopVape' src={A.imageVape} alt="" />
                            <div className="ProductsTopInfo">
                                <h1 style={{ "color": A.h1color }}>{A.h1text}</h1>
                                <h2 style={{ "color": A.h1color, "backdropFilter": "blur(10px)", "backgroundColor": "#ffffff22" }}>{A.h2text}</h2>
                                <button onClick={() => navigateTo(A.url)}>Learn More</button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

