import React from 'react'
import emailjs from 'emailjs-com';
import './Contact.scss'
import { Helmet } from "react-helmet";

export default function Contact() {

    function sendEmail(e) {
        // setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_vdkxpnd', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                // setPage1(false)
                // setPage2(false)
                // setPage3(true)
                // setFormSubmiting(false)
                // navigate('/thankpage')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="Contact">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Contact Us | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/contact" data-react-helmet="true" />
                <meta name="description" content="Kindly provide us with your contact details and share your request, inquiry, or suggestion in the space below. We value your feedback and will make every effort to respond to you promptly." data-react-helmet="true" />
                <meta property="og:title" content="Contact Us | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Kindly provide us with your contact details and share your request, inquiry, or suggestion in the space below. We value your feedback and will make every effort to respond to you promptly." data-react-helmet="true" />
                <meta property="og:site_name" content="Contact Us | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump contact us" data-react-helmet="true"></meta>
            </Helmet>

            {/* <Helmet>
                <title>Contact Us</title>
            </Helmet> */}
            <div className='ContactTop'>
                <h1>Contact Us</h1>
                <h2>TELL US MORE</h2>
                <h3>Kindly provide us with your contact details and share your request, inquiry, or suggestion in the space below. We value your feedback and will make every effort to respond to you promptly.</h3>
            </div>
            <form className="contact-form" onSubmit={sendEmail}>
                <input type="text" name="Full_Name" placeholder="* Full Name" required />
                <input type="email" name="Email" placeholder="* Email" required />
                <input type="tel" name="Phone" placeholder="Phone" />
                <input type="text" name="Country" placeholder="Country" />
                <textarea className="Message" name="Message" placeholder="* Tell us about your business " id="" cols="30" rows="10" required></textarea>
                <input type="submit" value="SEND" />
            </form>
        </div>
    )
}

