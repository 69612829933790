import React, { useState } from 'react'
import './Support.scss'
import Faq from "react-faq-component";
import { Helmet } from "react-helmet";

import Supportbg from '../../Images/Supportbg.jpeg'
import MenuImg from '../../Images/leftMenu.png'
import bumpVapeSimple1 from '../../Images/6.png'
import BGFLAVORS from '../../Images/BGFLAVORS.jpg'
import wholesaler from '../../Images/wholesaler.png'
import personalinformation from '../../Images/personalinformation.png'
import compliant from '../../Images/compliant.png'
import terms from '../../Images/terms.png'

export default function Support({ navigateTo }) {
    const data = {
        title: "",
        rows: [
            {
                title: "What is BUMP?",
                content: `BUMP has been dedicated to producing innovative products since 2019, focusing specifically on a smart heating system. However, they have also recognized the growing demand for fresh tastes and more flexible vaping options each year. In order to provide their customers with enhanced flavors and a consistent vaping experience, BUMP has made it a priority to adhere to their core values of enjoyment, reliability, and experience..`,
            },
            {
                title: "What is the age requirement for purchasing BUMP products?",
                content: "By using this product, you confirm that you are of legal smoking age in your state/country",
            },
            {
                title: "What are the main ingredients of e-liquid?",
                content: `The primary components utilized in our e-liquid include high-quality propylene glycol and vegetable glycerin sourced from food or pharmaceutical grade suppliers, a blend of natural and artificial flavors, and nicotine (excluding nicotine-free products).`,
            },
            {
                title: "How can I become your wholesaler?",
                content: `Kindly complete the wholesale request form by providing all the required information. Our dedicated sales representatives will reach out to you within a few business days to address your inquiry.`,
            },
            {
                title: "What is your wholesale pricing?",
                content: `We provide highly competitive wholesale pricing options. Once your wholesale application is approved, you will have the opportunity to engage in further discussions with our sales representatives regarding pricing details and any other specific requirements you may have.`,
            },
            {
                title: "How can I purchase your products?",
                content: `Our business exclusively focuses on wholesale operations. If you are interested in wholesale opportunities, kindly complete the wholesale request form by providing the required information. For personal or retail usage, we recommend contacting our official distributors in your country or region. If you require assistance in finding the local distributors, please feel free to contact us with your detailed address, and we will be glad to assist you in locating the nearest distributor in your area.`,
            },
            {
                title: "How to know when an BUMP is empty?",
                content: `When you are unable to produce any vapor despite the indicator still functioning, it indicates that the device is empty and requires refilling or replacement.`,
            },
            {
                title: "How long can an BUMP last?",
                content: `The longevity of the product varies based on individual vaping habits and frequency of use. Our laboratory data is measured at a rate of one puff per second. If you take longer puffs of 2-3 seconds or more, you may perceive the puffs to be insufficient. Additionally, factors such as power settings, draw resistance, battery capacity, weather conditions, and temperature can also influence the overall usage experience.
                Nevertheless, it is important to note that BUMP outlasts a significant majority (over 90%) of disposable e-cigarette pods available in the market. We have prioritized developing a product with an extended lifespan to provide a satisfactory vaping experience.`,
            },
        ],
    };

    const styles = {
        bgColor: 'white',
        titleTextColor: 'black',
        titleTextSize: '18px',
        rowTitleColor: 'black',
        // rowTitleTextSize: 'medium',
        rowContentColor: '#707070',
        rowContentTextSize: '15px',
        rowContentTextWeight: '300',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        // rowContentPaddingLeft: '50px',
        // rowContentPaddingRight: '150px',
        // arrowColor: "red",
        //transitionDuration: "1s",
        // timingFunc: "ease"
    };

    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
    };
    return (
        <div className="Support">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Support | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/support" data-react-helmet="true" />
                <meta name="description" content="Responsive customer service. Knowledgeable support team. Prompt assistance for any inquiries. Troubleshooting guidance. Warranty coverage for eligible issues. User-friendly online resources. Clear and detailed product manuals. Convenient communication channels. Dedicated to ensuring a smooth vaping experience. Commitment to customer satisfaction." data-react-helmet="true" />
                <meta property="og:title" content="Support | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Responsive customer service. Knowledgeable support team. Prompt assistance for any inquiries. Troubleshooting guidance. Warranty coverage for eligible issues. User-friendly online resources. Clear and detailed product manuals. Convenient communication channels. Dedicated to ensuring a smooth vaping experience. Commitment to customer satisfaction." data-react-helmet="true" />
                <meta property="og:site_name" content="Support | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Support" data-react-helmet="true"></meta>
            </Helmet>

            <div className="SupportHeader">
                <img src={Supportbg} alt="" />
                <h1>BUMP Support</h1>
            </div>
            <div className="SupportMenu">
                <div className="SupportMenuLeft">
                    <div onClick={() => navigateTo('contact')} className="SupportMenuBox">
                        <img src={wholesaler} alt="" />
                        <h3>Wholesale Request</h3>
                    </div>
                    <div onClick={() => navigateTo('about')} className="SupportMenuBox">
                        <img src={personalinformation} alt="" />
                        <h3>About Bump</h3>
                    </div>
                </div>
                <div className="SupportMenuRight">
                    <div onClick={() => navigateTo('privacypolicy')} className="SupportMenuBox">
                        <img src={compliant} alt="" />
                        <h3>Privacy Policy</h3>
                    </div>
                    <div onClick={() => navigateTo('contact')} className="SupportMenuBox">
                        <img src={terms} alt="" />
                        <h3>Contact Us</h3>
                    </div>
                </div>
            </div>
            <div className="SupportFaq">
                <div className="SupportFaqHeader">
                    <h1>FAQ</h1>
                </div>
                <div className="SupportFaqMain">
                    <Faq
                        data={data}
                        styles={styles}
                        config={config}
                    />
                </div>
            </div>
            <div className="SupportFaqBanner">
                <div className="SupportFaqBannerTop">
                    <h1>The Story Behind Us</h1>
                </div>
                <div className="SupportFaqBannerBottom">
                    <div className="SupportFaqBannerInfo">
                        <h1>THE BUMP GAME</h1>
                        <h2>Our bump vape company was born out of a desire to revolutionize the vaping industry with cutting-edge technology and exceptional product design.</h2>
                        <h3 onClick={() => navigateTo('about')} >Learn More</h3>
                    </div>
                    <div className="SupportFaqBannerImages">
                        <img className='SupportFaqBannerImages1' src={bumpVapeSimple1} alt="" />
                        <img className='SupportFaqBannerImages2' src={BGFLAVORS} alt="" />
                    </div>

                </div>
            </div>
        </div>
    )
}

