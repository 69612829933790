import React, { useState } from 'react'
import './PrivacyPolicy.scss'
import { Helmet } from "react-helmet";

export default function PrivacyPolicy({ navigateTo }) {

    return (
        <div className="PrivacyPolicy">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Privacy Policy | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/privacypolicy" data-react-helmet="true" />
                <meta name="description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all users of our website, product, and software." data-react-helmet="true" />
                <meta property="og:title" content="Privacy Policy | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all users of our website, product, and software." data-react-helmet="true" />
                <meta property="og:site_name" content="Privacy Policy | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Privacy Policy" data-react-helmet="true"></meta>
            </Helmet>

            <div className="PrivacyPolicyHeader">
                <h1>Privacy Policy</h1>
                <h2>Updated May 11, 2023</h2>
            </div>
            <div className="PrivacyPolicyMain">

                <h3>Effective Date: May 11, 2023</h3>
                <h4>
                    Protecting your privacy while using our website, product, and software is of utmost importance to us, especially when it involves conducting business or sharing sensitive personal information. This Privacy Notice serves as the governing document for our Privacy Policy, applying to all users of our website, product, and software.
                </h4>


                <h3>Your Personal Information</h3>
                <h4>
                    We collect customer information to personalize and enhance our customer service. Here are some details regarding the information we gather from our users:
                    <br />
                    <br />
                    Email Address: When you subscribe to our newsletter, we collect your email address as essential information. Rest assured that we store your email address encrypted and it will NOT be disclosed to any third party.
                    <br />
                    <br />
                    Cookies: Presently, we have integrated a Google Analytics tracking code on our website. Google uses cookies to store visitor information, aiding in traffic analysis. All the data collected by Google is anonymous, and no personally identifiable information is stored. In essence, neither Google nor BUMP will be able to identify you. If you have concerns, you can choose to reject the acceptance of cookies by adjusting your web browser settings when browsing our official website.
                </h4>

                <h3>Links to Other Sites</h3>
                <h4>
                    For your convenience and information, we may provide links to other websites. Please note that these sites operate independently from Thebumpvape.com and may have their own privacy policies in place. We strongly recommend reviewing their privacy notices if you visit any linked websites. BUMP holds no responsibility for the content or usage of unaffiliated websites you choose to access.
                </h4>

                <h3>Age Limitation</h3>
                <h4>
                    Our products are intended for adult smokers and vapers who are at least 21 years old or meet the minimum smoking age requirement in your state. We do not intentionally collect or solicit personal information from individuals under the age of 21. In the event that we discover any information received from an individual under the age of 21, which violates this policy, we will promptly delete such information. If you believe we have collected information from or about anyone under 21, please contact us immediately.
                </h4>

                <h3>Conditions of Use, Notices, and Revisions</h3>
                <h4>
                    By visiting Thebumpvape.com, you agree that your visit and any privacy-related disputes are subject to this Notice and our Conditions of Use, including limitations on damages and the arbitration of disputes. Should you have any concerns regarding privacy at Thebumpvape.com, please reach out to us with a detailed description, and we will make every effort to resolve the matter.
                    <br />
                    <br />
                    As our business evolves, our Privacy Notice and Conditions of Use may change accordingly. We may send periodic reminders of our notices and conditions via email unless you have specifically requested not to receive them. We encourage you to check our website regularly for the most recent updates. Unless otherwise specified, our current Privacy Notice applies to all the information we have about you and your account. In the event of any significant changes to our Privacy Policy, we will post a notice on this site to inform you.
                </h4>
                <h5>
                    Contact: For any further inquiries or concerns
                    <br />
                    <br />
                    please email us at info@thebumpvape.com.
                </h5>
            </div>
        </div>
    )
}

