import React from 'react'
import leftMenu from '../../Images/leftMenu.png'
import LogoBump from '../../Images/LogoBump.png'
import './Header.scss';
import $ from 'jquery';
import { useLocation } from "react-router-dom";

export default function Header({ setShowMenu,navigateTo }) {
    // const { pathname } = useLocation();
    // if (pathname === "/order") return null;
    return (
        <div className="Header">
            <img onClick={()=>{navigateTo('')}} src={LogoBump} alt="" />
            <img onClick={() => {
                setShowMenu(true)
            }} src={leftMenu} alt="" />
        </div>
    )
}
