import React from 'react'
import emailjs from 'emailjs-com';
import './Wholesale.scss'
import { Helmet } from "react-helmet";

export default function Wholesale() {

    function sendEmail(e) {
        // setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_la5kvj4', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                // setPage1(false)
                // setPage2(false)
                // setPage3(true)
                // setFormSubmiting(false)
                // navigate('/thankpage')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div className="Wholesale">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Wholesale | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/wholesale" data-react-helmet="true" />
                <meta name="description" content="Bump Vape offers competitive wholesale options for businesses, ensuring attractive profit margins. Bulk ordering discounts available. High-quality products that cater to diverse customer preferences. Reliable and timely shipping. Dedicated support for wholesale partners. Flexible ordering quantities to accommodate different business needs." data-react-helmet="true" />
                <meta property="og:title" content="Wholesale | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Bump Vape offers competitive wholesale options for businesses, ensuring attractive profit margins. Bulk ordering discounts available. High-quality products that cater to diverse customer preferences. Reliable and timely shipping. Dedicated support for wholesale partners. Flexible ordering quantities to accommodate different business needs." data-react-helmet="true" />
                <meta property="og:site_name" content="Wholesale | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, wholesale BUMP, wholesale vape, wholesale e-cigarette, wholesale business, wholesaler, become wholesaler" data-react-helmet="true"></meta>
            </Helmet>
            <div className='WholesaleTop'>
                <h1>WHOLESALE REQUEST</h1>
                <h2>JOIN THE VAPE REVOLUTION</h2>
                <h3>If you are interested in becoming a distributor for Bumo Vapes, please reach out to us below. </h3>
            </div>
            <form className="Wholesale-form" onSubmit={sendEmail}>
                <input type="text" name="Full_Name" placeholder="* Full Name" required />
                <input type="email" name="Email" placeholder="* Email" required />
                <input type="text" name="Business_Name" placeholder="* Business Name" required />
                <input type="tel" name="Phone" placeholder="* Phone" required />
                <div className="WholesaleUsProduct">
                    <select defaultValue='Infinty' name="WholesaleUsProduct" id="" required>
                        <option value="Infinty">Infinty</option>
                        <option value="Genesis">Genesis</option>
                        <option value="Pro X">Pro X</option>
                    </select>
                    <select defaultValue='100 - 1K' name="Quantity" id="" required>
                        <option value="100 - 1K">100 - 1K</option>
                        <option value="1K - 10K">1K - 10K</option>
                        <option value="10K - 100K">10K - 100K</option>
                        <option value="100K - 300K">100K - 300K</option>
                        <option value="300K - 500K">300K - 500K</option>
                        <option value="+500K">+500K</option>
                    </select>
                    {/* <input type="text" name="Quantity" id="" placeholder="* Quantity " required/> */}
                </div>
                <input type="address" name="Address" placeholder="* Address" required />
                <div className="WholesaleUsAddress">
                    <input type="text" name="City" id="" placeholder="* City " required />
                    <input type="text" name="State" id="" placeholder="* State " required />
                    <input type="text" name="" id="Zip_Code" placeholder="* Zip Code " required />
                </div>
                <input type="text" name="Country" placeholder="* Country" required />
                <textarea className="Message" name="Message" placeholder="* Tell us about your business " id="" cols="30" rows="10" required></textarea>
                {/* <input type="hidden" name="_next" value="http://localhost:3000/thankspage" /> */}
                {/* <input type="hidden" name="_captcha" value="false" /> */}
                <input type="submit" value="SEND" />

            </form>
        </div>
    )
}

