import React, { useState } from 'react'
import { useEffect } from 'react';
import './SalesMaterial.scss'
import { useLocation } from 'react-router-dom';

import $ from 'jquery';
// images
import BGFLAVORS2 from '../../Images/BGFLAVORS2.webp'
import AboutBG3 from '../../Images/AboutBG3.jpeg'
import AboutBG4 from '../../Images/AboutBG4.jpg'
import fdawarning from '../../Images/fdawarning.webp'
import downloadImg from '../../Images/download.png'
import shareImg from '../../Images/share.png'
import bumpVapeSimple1 from '../../Images/6.png'
// zip
import Catalog2023zip from '../Data/BumpZip/Catalog 2023.zip'
import BumpSalesMaterialzip from '../Data/BumpZip/Bump Sales Material.zip'
import OrderFormBumpzip from '../Data/BumpZip/Order Form Bump.zip'
import BumpInfinityzip from '../Data/BumpZip/Bump Infinity.zip'
import BumpGenesiszip from '../Data/BumpZip/Bump Genesis.zip'
import BumpProXzip from '../Data/BumpZip/Bump Pro X.zip'
// pdf
import Catalog2023pdf from '../Data/BumpPdf/Vapes Catalogs.pdf'
import OrderFormBumppdf from '../Data/BumpPdf/Order Form Bump.pdf'
import BumpInfinitypdf from '../Data/BumpPdf/Bump Infinity.pdf'
import BumpGenesispdf from '../Data/BumpPdf/Bump Genesis.pdf'
import BumpProXpdf from '../Data/BumpPdf/Bump Pro X.pdf'

export default function SalesMaterial({ navigateTo }) {
    const location = useLocation().pathname.split('/')[1]
    const location2 = useLocation().search.split('=')[1]


    // useEffect(() => {
    //     navigateTo(location + '?window=salesmaterial')
    // }, []);

    return (
        <div className="SalesMaterial">
            <div className="SalesMaterialHeader">
                {/* <img src={fdawarning} alt="" /> */}
                <div className="SalesMaterialHeaderTitle">
                    <h1>Welcom to BUMP Downloads</h1>
                    <h2>Our sales material downloads page offers a wide range of resources to meet your needs.</h2>
                </div>
                <div className="SalesMaterialHeaderMenu">
                    <h1
                        style={location2 == 'salesmaterial' || location2 == undefined ? { "backgroundColor": "black", "color": "white" } : null}
                        onClick={() => navigateTo(location + '?window=salesmaterial')}>
                        Sales
                        Material</h1>
                    <h1
                        style={location2 == 'products' ? { "backgroundColor": "black", "color": "white" } : null}
                        onClick={() => navigateTo(location + '?window=products')}>
                        Products
                    </h1>
                </div>
            </div>
            {console.log(useLocation().search)}
            {
                location2 == 'salesmaterial' || location2 == undefined ?
                    <div className="SalesMaterialMenu">
                        <div className="SalesMaterialBox">
                            <div className="SalesMaterialBoxInfo">
                                <h2>2023 CATALOG</h2>
                                {/* <h3>Discover our cutting-edge vape catalog for 2023, featuring sleek designs, advanced technology, and a wide range of tantalizing flavors. Vape in style!</h3> */}
                            </div>
                            <div className="SalesMaterialBoxImg">
                                <img src={AboutBG3} alt="" />
                            </div>
                            <a className="downloadImgBtn" href={Catalog2023zip} download>
                                <h3>DOWNLOAD</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" href={Catalog2023pdf} target='_blank' rel='noopener noreferrer'>
                                <h3>PREVIEW</h3>
                                <img src={shareImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>OTHERS</h3>
                                <img style={{ "opacity": "30%" }} src={downloadImg} alt="" />
                            </a>
                        </div>
                        <div className="SalesMaterialBox">
                            <div className="SalesMaterialBoxInfo">
                                <h2>SALES MATERIAL</h2>
                                {/* <h3>Equip your sales team with our dynamic sales material files for vape products. Captivate customers, highlight product benefits, and boost your sales performance. Drive vape success now!</h3> */}
                            </div>
                            <div className="SalesMaterialBoxImg">
                                <img src={AboutBG4} alt="" />
                            </div>
                            <a className="downloadImgBtn" href={BumpSalesMaterialzip} download>
                                <h3>DOWNLOAD</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>PREVIEW</h3>
                                <img style={{ "opacity": "30%" }} src={shareImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>OTHERS</h3>
                                <img style={{ "opacity": "30%" }} src={downloadImg} alt="" />
                            </a>
                        </div>
                        <div className="SalesMaterialBox">
                            <div className="SalesMaterialBoxInfo">
                                <h2>ORDER FORM</h2>
                                {/* <h3>Equip your sales team with our dynamic sales material files for vape products. Captivate customers, highlight product benefits, and boost your sales performance. Drive vape success now!</h3> */}
                            </div>
                            <div className="SalesMaterialBoxImg">
                                <img src={AboutBG4} alt="" />
                            </div>
                            <a className="downloadImgBtn" href={OrderFormBumpzip} download>
                                <h3>DOWNLOAD</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" href={OrderFormBumppdf} target='_blank' rel='noopener noreferrer'>
                                <h3>PREVIEW</h3>
                                <img src={shareImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>OTHERS</h3>
                                <img style={{ "opacity": "30%" }} src={downloadImg} alt="" />
                            </a>
                        </div>
                        <div className="SalesMaterialBox">
                            <div className="SalesMaterialBoxInfo">
                                <h2>OFFICIAL WEBSITE</h2>
                                {/* <h3> Welcome to our vape official website, your one-stop destination for premium vaping products, expert guides, and a thriving community of vape enthusiasts. Explore and indulge!</h3> */}
                            </div>
                            <div className="SalesMaterialBoxImg">
                                <img src={BGFLAVORS2} alt="" />
                            </div>
                            <a className="downloadImgBtn" onClick={() => navigateTo('')}>
                                <h3>TAKE ME TO THE WEBSITE</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>PREVIEW</h3>
                                <img style={{ "opacity": "30%" }} src={shareImg} alt="" />
                            </a>
                            <a style={{ "opacity": "30%" }} className="downloadImgBtn" >
                                <h3>OTHERS</h3>
                                <img src={downloadImg} alt="" />
                            </a>

                        </div>
                    </div>
                    :
                    null
            }
            {
                location2 == 'products' ?
                    <div className="SalesMaterialMenu">
                        <div className="SalesMaterialBox">
                            <div className="SalesMaterialBoxInfo">
                                <h2>INFINITY</h2>
                                {/* <h3>Discover our cutting-edge vape catalog for 2023, featuring sleek designs, advanced technology, and a wide range of tantalizing flavors. Vape in style!</h3> */}
                            </div>
                            <div className="SalesMaterialBoxImg2">
                                <img src={bumpVapeSimple1} alt="" />
                            </div>
                            <a className="downloadImgBtn" href={BumpInfinityzip} download>
                                <h3>DOWNLOAD</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" href={BumpInfinitypdf} target='_blank' rel='noopener noreferrer'>
                                <h3>PREVIEW</h3>
                                <img src={shareImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>OTHERS</h3>
                                <img style={{ "opacity": "30%" }} src={downloadImg} alt="" />
                            </a>
                        </div>
                        <div className="SalesMaterialBox">
                            <div className="SalesMaterialBoxInfo">
                                <h2>GENESIS</h2>
                                {/* <h3>Discover our cutting-edge vape catalog for 2023, featuring sleek designs, advanced technology, and a wide range of tantalizing flavors. Vape in style!</h3> */}
                            </div>
                            <div className="SalesMaterialBoxImg2">
                                <img src={bumpVapeSimple1} alt="" />
                            </div>
                            <a className="downloadImgBtn" href={BumpGenesiszip} download>
                                <h3>DOWNLOAD</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" href={BumpGenesispdf} target='_blank' rel='noopener noreferrer'>
                                <h3>PREVIEW</h3>
                                <img src={shareImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>OTHERS</h3>
                                <img style={{ "opacity": "30%" }} src={downloadImg} alt="" />
                            </a>
                        </div>
                        <div className="SalesMaterialBox">
                            <div className="SalesMaterialBoxInfo">
                                <h2>PRO X</h2>
                                {/* <h3>Discover our cutting-edge vape catalog for 2023, featuring sleek designs, advanced technology, and a wide range of tantalizing flavors. Vape in style!</h3> */}
                            </div>
                            <div className="SalesMaterialBoxImg2">
                                <img src={bumpVapeSimple1} alt="" />
                            </div>
                            <a className="downloadImgBtn" href={BumpProXzip} download>
                                <h3>DOWNLOAD</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" href={BumpProXpdf} target='_blank' rel='noopener noreferrer'>
                                <h3>PREVIEW</h3>
                                <img src={shareImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" >
                                <h3 style={{ "opacity": "30%" }}>OTHERS</h3>
                                <img style={{ "opacity": "30%" }} src={downloadImg} alt="" />
                            </a>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

