import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import { useEffect } from 'react';
import './NickyJam.scss'
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
// pdf
import Catalog2023pdf from '../Data/BumpPdf/Fume Vapors X NickyJam.pdf'
import Catalog2023zip from '../Data/BumpZip/Fume Vapors X NickyJam.zip'
import NickyJamBanner from '../../Images/NickyJamBanner.webp'
import downloadImg from '../../Images/download.png'

export default function NickyJam({ navigateTo }) {
    const location = useLocation().pathname.split('/')[1]
    const location2 = useLocation().search.split('=')[1]
    const [MessageSubmit, setMessageSubmit] = useState(false);
    const search = useLocation().search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const foo = params.get('w'); // bar

    function sendEmail(e) {
        // setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_la5kvj4', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                var frm = document.getElementsByClassName('Wholesale-form')[0];
                frm.reset();
                setMessageSubmit(true)
                // setPage1(false)
                // setPage2(false)
                // setPage3(true)
                // setFormSubmiting(false)
                // navigate('/thankpage')
                // window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }


    // useEffect(() => {
    //     navigateTo(location + '?window=NickyJam')
    // }, []);

    return (
        <div className="NickyJam">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Wholesale | Fume X NickyJam</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/wholesale" data-react-helmet="true" />
                <meta name="description" content="Bump Vape offers competitive wholesale options for businesses, ensuring attractive profit margins. Bulk ordering discounts available. High-quality products that cater to diverse customer preferences. Reliable and timely shipping. Dedicated support for wholesale partners. Flexible ordering quantities to accommodate different business needs." data-react-helmet="true" />
                <meta property="og:title" content="Wholesale | Fume X NickyJam" data-react-helmet="true" />
                <meta property="og:description" content="Bump Vape offers competitive wholesale options for businesses, ensuring attractive profit margins. Bulk ordering discounts available. High-quality products that cater to diverse customer preferences. Reliable and timely shipping. Dedicated support for wholesale partners. Flexible ordering quantities to accommodate different business needs." data-react-helmet="true" />
                <meta property="og:site_name" content="Wholesale | Fume X NickyJam" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, wholesale BUMP, wholesale vape, wholesale e-cigarette, wholesale business, wholesaler, become wholesaler" data-react-helmet="true"></meta>
            </Helmet>
            {/* Warning */}
            <div className="NickyJamWarning">
                <h1>WARNING: This product contains nicotine. Nicotine is an addictive chemical.</h1>
            </div>
            {/* HeaderImh */}
            <div className="HeaderImg">
                <img src={NickyJamBanner} alt="" />
            </div>
            {/* Header */}
            <div className="NickyJamHeader">
                <div className="NickyJamHeaderTitle">
                    <h1>Welcom to Wholesale</h1>
                    <h2>Unlock a world of premium content: Access our media kit for resale and elevate your wholesale experience!</h2>
                </div>
                <div className="NickyJamHeaderMenu">
                    <h1
                        style={foo == 'main' || foo == undefined ? { "backgroundColor": "black", "color": "white" } : null}
                        onClick={() => navigateTo(location + '?w=main')}>
                        MAIN</h1>
                    <h1
                        style={foo == 'wholesale' ? { "backgroundColor": "black", "color": "white" } : null}
                        onClick={() => navigateTo(location + '?w=wholesale')}>
                        WHOLESALE REQUEST</h1>
                    <h1
                        style={foo == 'catalog' ? { "backgroundColor": "black", "color": "white" } : null}
                        onClick={() => navigateTo(location + '?w=catalog')}>
                        CATALOG</h1>
                    <h1
                        style={foo == 'material' ? { "backgroundColor": "black", "color": "white" } : null}
                        onClick={() => navigateTo(location + '?w=material')}>
                        MATERIALS</h1>
                </div>
            </div>
            {/* PDF */}


            {/* main */}
            {
                foo == 'main' || location == 'nickyjam' && foo == undefined ?
                    <div className="NickyJamContactUs">
                        {/* Catalog */}
                        <div className="NickyJamContactUsFrame">
                            <h1>2024 CATALOG</h1>
                            <div className="downloadImgBtnMain">

                                <a className="downloadImgBtn" href={Catalog2023pdf} target='_blank' rel='noopener noreferrer'>
                                    <h3>PREVIEW CATALOG</h3>
                                    <img src={downloadImg} alt="" />
                                </a>
                                <a className="downloadImgBtn" href={Catalog2023zip} download>
                                    <h3>DOWNLOAD CATALOG</h3>
                                    <img src={downloadImg} alt="" />
                                </a>
                            </div>
                            <iframe
                                src='https://simplebooklet.com/embed.php?wpKey=Oz5alTr4tS0dNXVuNf5qFw&source=embed'
                                allowfullscreen
                                width='100%'
                                height={window.screen.width < 890 ? '500' : '960'}
                                frameborder="0"
                                scrolling='no'>

                            </iframe>

                            {/* Flavors */}
                            <h1>Nicky Jam Flavours</h1>
                            <h6>Watch & Download</h6>

                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-4060fb6 e-flex e-con-boxed e-con e-child" data-id="4060fb6" data-element_type="container">
                                    <div class="e-con-inner">
                                        <div class="elementor-element elementor-element-04ca307 elementor-align-center elementor-widget elementor-widget-button" data-id="04ca307" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1rl3a3Vi8ksjPibAVMv_LpmD1XwJywaCz?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">69 Bananas</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-8f1a43f elementor-widget elementor-widget-button" data-id="8f1a43f" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1W0_HeWtbvoW_bS3Kj0ahC6ZBTAAtL4qX?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Black hat Black ice</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-f34ba55 elementor-widget elementor-widget-button" data-id="f34ba55" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/16IZPItFw6FUF3uGePBT8R5unY9Y4Masp?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Blue Miami</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-039e0cb elementor-widget elementor-widget-button" data-id="039e0cb" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1s3eqSinHSub8E-W9ZgE5-1rZow3XRzG7?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Calor Juice</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-6d8155a elementor-widget elementor-widget-button" data-id="6d8155a" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/17woCvn15nrc6V_uEdsx-DCDWkRQfhYx4?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Cangri Bear</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-880ab3d elementor-widget elementor-widget-button" data-id="880ab3d" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1mrJXjPWu6BkJf_KA4BlbUNg5ltKYQwnz?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">El Ganador</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-a899fe5 e-flex e-con-boxed e-con e-child" data-id="a899fe5" data-element_type="container">
                                    <div class="e-con-inner">
                                        <div class="elementor-element elementor-element-b8bbb4f elementor-align-center elementor-widget elementor-widget-button" data-id="b8bbb4f" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1idP_yGMqvnfnSeyjA9MWuNjxrk0F4AdF?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Fantasia Fume</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-180f9c1 elementor-widget elementor-widget-button" data-id="180f9c1" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1XKREKwCzvVVWTSZTZKiNX8IG_vpr9kzy?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Fenix Beach</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-7fc1d3b elementor-widget elementor-widget-button" data-id="7fc1d3b" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1azREWU3ZEWv69rsm5X5l0p10Cs5pr8n_?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Fresh Wine Up</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-7ca4e63 elementor-widget elementor-widget-button" data-id="7ca4e63" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1hE_PNkcWjcelqzkWi2rH-ikNYaM41SCK?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Lush Medellin</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-713e50e elementor-widget elementor-widget-button" data-id="713e50e" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1gbp1C1nvG5OolfS8CtGQgCmYnGYOTW_2?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Menta en la Disco</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-3c2ec46 elementor-widget elementor-widget-button" data-id="3c2ec46" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1MSSvxtCZnVUvf75mFnqWuzJp-Zc8x8dy?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Miamint</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-c77bd18 e-flex e-con-boxed e-con e-child" data-id="c77bd18" data-element_type="container">
                                    <div class="e-con-inner">
                                        <div class="elementor-element elementor-element-9781d26 elementor-align-center elementor-widget elementor-widget-button" data-id="9781d26" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1GNZjG7AATP3ejot91U9kkTy_PBd-gdPv?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Ojos Rojos</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-305d28c elementor-widget elementor-widget-button" data-id="305d28c" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1c6GxsihYqtpLdONRpHJdjYgCqpVSDtjQ?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Peach XXX</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-37f36f2 elementor-widget elementor-widget-button" data-id="37f36f2" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1mGQw0ApuP3qk3Z7kN7jRpLphYjbH8rXb?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Summer Amante</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-1e0ca23 elementor-widget elementor-widget-button" data-id="1e0ca23" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/13JRRkY6R2riLzFoXwGaKm1YfXNQ19yf8?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Sweet Gatas</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-a09bd71 elementor-widget elementor-widget-button" data-id="a09bd71" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1DwqIPlg9JPtWpvRsxTMIpaeVRLmwqGCG?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Toy a Mil</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-d1ffa8d elementor-widget elementor-widget-button" data-id="d1ffa8d" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1sdw2NDkNfIK9C5oyil8irgSELa03HA95?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Yellow Amanecer</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Videos */}
                            <h1>Videos</h1>
                            <div className="NickyJamContactUsVideis">
                                <a href="https://drive.google.com/drive/folders/10fuTSy-lmGELLGO_1YyOEjuXxmP0z_Af" target='_blank'>Watch & Download Videos</a>
                            </div>
                        </div>
                        {/* Contact us */}
                        <div className='ContactTop'>
                            <h1>WHOLESALE REQUEST</h1>
                            <h2>If you are interested in becoming a distributor for Fume X Nicky Jam x fume Vapes, please reach out to us below</h2>
                        </div>
                        <form className="Wholesale-form" onSubmit={sendEmail}>
                            <span>
                                <h2>Full Name<h3>*</h3></h2>
                                <input type="text" name="Full_Name" placeholder="Full Name" required />
                            </span>
                            <span>
                                <h2>Email<h3>*</h3></h2>
                                <input type="email" name="Email" placeholder="Email" required />
                            </span>
                            <span>
                                <h2>Business Name<h3>*</h3></h2>
                                <input type="text" name="Business_Name" placeholder="Business Name" required />
                            </span>
                            <span>
                                <h2>Phone<h3>*</h3></h2>
                                <input type="tel" name="Phone" placeholder="Phone" required />
                            </span>
                            <span>
                                <h2>Business Address<h3>*</h3></h2>
                                <input type="address" name="Address" placeholder="Address" required />
                            </span>
                            <span>
                                <h2>City <h3>*</h3></h2>
                                <input type="text" name="City" id="" placeholder="City " required />
                            </span>
                            <div className="WholesaleUsAddress">
                                <span>
                                    <h2>State <h3>*</h3></h2>
                                    <input type="text" name="State" id="" placeholder="State " required />
                                </span>
                                <span>
                                    <h2>Zip Code <h3>*</h3></h2>
                                    <input type="text" name="" id="Zip_Code" placeholder="Zip Code " required />
                                </span>
                            </div>

                            <span>
                                <h2>Share your interest with us</h2>
                                <textarea className="Message" name="Message" placeholder="Share your interest with us" id="" cols="30" rows="10"></textarea>
                            </span>
                            <span>
                                <input type="submit" value="SEND" />
                            </span>
                        </form>
                    </div>
                    :
                    null
            }

            {/* Page1 */}
            {
                foo == 'wholesale' ?
                    <div className="NickyJamMenu">
                        {/* Contact us */}
                        <div className='ContactTop'>
                            <h1>WHOLESALE REQUEST</h1>
                            <h2>If you are interested in becoming a distributor for Fume X Nicky Jam x fume Vapes, please reach out to us below</h2>
                        </div>
                        <form className="Wholesale-form" onSubmit={sendEmail}>
                            <span>
                                <h2>Full Name<h3>*</h3></h2>
                                <input type="text" name="Full_Name" placeholder="Full Name" required />
                            </span>
                            <span>
                                <h2>Email<h3>*</h3></h2>
                                <input type="email" name="Email" placeholder="Email" required />
                            </span>
                            <span>
                                <h2>Business Name<h3>*</h3></h2>
                                <input type="text" name="Business_Name" placeholder="Business Name" required />
                            </span>
                            <span>
                                <h2>Phone<h3>*</h3></h2>
                                <input type="tel" name="Phone" placeholder="Phone" required />
                            </span>
                            <span>
                                <h2>Business Address<h3>*</h3></h2>
                                <input type="address" name="Address" placeholder="Address" required />
                            </span>
                            <span>
                                <h2>City <h3>*</h3></h2>
                                <input type="text" name="City" id="" placeholder="City " required />
                            </span>
                            <div className="WholesaleUsAddress">
                                <span>
                                    <h2>State <h3>*</h3></h2>
                                    <input type="text" name="State" id="" placeholder="State " required />
                                </span>
                                <span>
                                    <h2>Zip Code <h3>*</h3></h2>
                                    <input type="text" name="" id="Zip_Code" placeholder="Zip Code " required />
                                </span>
                            </div>

                            <span>
                                <h2>Share your interest with us</h2>
                                <textarea className="Message" name="Message" placeholder="Share your interest with us" id="" cols="30" rows="10"></textarea>
                            </span>
                            <span>
                                <input type="submit" value="SEND" />
                            </span>
                        </form>
                    </div>
                    :
                    null
            }

            {/* Page2 */}
            {
                foo == 'catalog' ?
                    <div className="NickyJamCatalog">
                        <h1>2024 CATALOG</h1>
                        <div className="downloadImgBtnMain">
                            <a className="downloadImgBtn" href={Catalog2023pdf} target='_blank' rel='noopener noreferrer'>
                                <h3>PREVIEW CATALOG</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                            <a className="downloadImgBtn" href={Catalog2023zip} download>
                                <h3>DOWNLOAD CATALOG</h3>
                                <img src={downloadImg} alt="" />
                            </a>
                        </div>
                        <iframe
                            src='https://simplebooklet.com/embed.php?wpKey=Oz5alTr4tS0dNXVuNf5qFw&source=embed'
                            allowfullscreen
                            width='100%'
                            height={window.screen.width < 890 ? '500' : '960'}
                            frameborder="0"
                            scrolling='no'>

                        </iframe>
                    </div>
                    :
                    null
            }

            {/* Page3 */}
            {
                foo == 'material' ?
                    <div className="NickyJamContactUs">

                        <div className="NickyJamsalesmaterial">
                            {/* Flavors */}
                            <h1>Nicky Jam Flavours</h1>
                            <h6>Watch & Download</h6>
                            <div class="e-con-inner">
                                <div class="elementor-element elementor-element-4060fb6 e-flex e-con-boxed e-con e-child" data-id="4060fb6" data-element_type="container">
                                    <div class="e-con-inner">
                                        <div class="elementor-element elementor-element-04ca307 elementor-align-center elementor-widget elementor-widget-button" data-id="04ca307" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1rl3a3Vi8ksjPibAVMv_LpmD1XwJywaCz?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">69 Bananas</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-8f1a43f elementor-widget elementor-widget-button" data-id="8f1a43f" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1W0_HeWtbvoW_bS3Kj0ahC6ZBTAAtL4qX?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Black hat Black ice</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-f34ba55 elementor-widget elementor-widget-button" data-id="f34ba55" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/16IZPItFw6FUF3uGePBT8R5unY9Y4Masp?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Blue Miami</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-039e0cb elementor-widget elementor-widget-button" data-id="039e0cb" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1s3eqSinHSub8E-W9ZgE5-1rZow3XRzG7?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Calor Juice</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-6d8155a elementor-widget elementor-widget-button" data-id="6d8155a" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/17woCvn15nrc6V_uEdsx-DCDWkRQfhYx4?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Cangri Bear</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-880ab3d elementor-widget elementor-widget-button" data-id="880ab3d" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1mrJXjPWu6BkJf_KA4BlbUNg5ltKYQwnz?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">El Ganador</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-a899fe5 e-flex e-con-boxed e-con e-child" data-id="a899fe5" data-element_type="container">
                                    <div class="e-con-inner">
                                        <div class="elementor-element elementor-element-b8bbb4f elementor-align-center elementor-widget elementor-widget-button" data-id="b8bbb4f" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1idP_yGMqvnfnSeyjA9MWuNjxrk0F4AdF?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Fantasia Fume</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-180f9c1 elementor-widget elementor-widget-button" data-id="180f9c1" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1XKREKwCzvVVWTSZTZKiNX8IG_vpr9kzy?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Fenix Beach</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-7fc1d3b elementor-widget elementor-widget-button" data-id="7fc1d3b" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1azREWU3ZEWv69rsm5X5l0p10Cs5pr8n_?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Fresh Wine Up</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-7ca4e63 elementor-widget elementor-widget-button" data-id="7ca4e63" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1hE_PNkcWjcelqzkWi2rH-ikNYaM41SCK?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Lush Medellin</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-713e50e elementor-widget elementor-widget-button" data-id="713e50e" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1gbp1C1nvG5OolfS8CtGQgCmYnGYOTW_2?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Menta en la Disco</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-3c2ec46 elementor-widget elementor-widget-button" data-id="3c2ec46" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1MSSvxtCZnVUvf75mFnqWuzJp-Zc8x8dy?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Miamint</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="elementor-element elementor-element-c77bd18 e-flex e-con-boxed e-con e-child" data-id="c77bd18" data-element_type="container">
                                    <div class="e-con-inner">
                                        <div class="elementor-element elementor-element-9781d26 elementor-align-center elementor-widget elementor-widget-button" data-id="9781d26" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1GNZjG7AATP3ejot91U9kkTy_PBd-gdPv?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Ojos Rojos</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-305d28c elementor-widget elementor-widget-button" data-id="305d28c" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1c6GxsihYqtpLdONRpHJdjYgCqpVSDtjQ?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Peach XXX</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-37f36f2 elementor-widget elementor-widget-button" data-id="37f36f2" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1mGQw0ApuP3qk3Z7kN7jRpLphYjbH8rXb?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Summer Amante</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-1e0ca23 elementor-widget elementor-widget-button" data-id="1e0ca23" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/13JRRkY6R2riLzFoXwGaKm1YfXNQ19yf8?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Sweet Gatas</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-a09bd71 elementor-widget elementor-widget-button" data-id="a09bd71" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1DwqIPlg9JPtWpvRsxTMIpaeVRLmwqGCG?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Toy a Mil</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elementor-element elementor-element-d1ffa8d elementor-widget elementor-widget-button" data-id="d1ffa8d" data-element_type="widget" data-widget_type="button.default">
                                            <div class="elementor-widget-container">
                                                <div class="elementor-button-wrapper">
                                                    <a class="elementor-button elementor-button-link elementor-size-sm" href="https://drive.google.com/drive/folders/1sdw2NDkNfIK9C5oyil8irgSELa03HA95?usp=drive_link" target="_blank">
                                                        <span class="elementor-button-content-wrapper">
                                                            <span class="elementor-button-icon elementor-align-icon-left">
                                                                <svg aria-hidden="true" class="e-font-icon-svg e-fas-images" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M480 416v16c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V176c0-26.51 21.49-48 48-48h16v208c0 44.112 35.888 80 80 80h336zm96-80V80c0-26.51-21.49-48-48-48H144c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h384c26.51 0 48-21.49 48-48zM256 128c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-96 144l55.515-55.515c4.686-4.686 12.284-4.686 16.971 0L272 256l135.515-135.515c4.686-4.686 12.284-4.686 16.971 0L512 208v112H160v-48z"></path></svg>			</span>
                                                            <span class="elementor-button-text">Yellow Amanecer</span>
                                                        </span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Videos */}
                            <h1>Videos</h1>
                            <div className="NickyJamContactUsVideis">
                                <a href="https://drive.google.com/drive/folders/10fuTSy-lmGELLGO_1YyOEjuXxmP0z_Af" target='_blank'>Watch & Download Videos</a>
                            </div>
                        </div>

                    </div>
                    :
                    null
            }


            {/* Submitted message */}
            {MessageSubmit ?
                <div className="MessageSubmit">
                    <div className="MessageSubmitBox">
                        <h2>Thank you.</h2>
                        <h1>We have received your inquiry and we will contact you as soon as possible regarding your order</h1>
                        <button onClick={() => {
                            setMessageSubmit(false)
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                        }}>Close</button>
                    </div>
                </div>
                : null}
        </div>
    )
}

