import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Contact from '../Components/Contact/Contact'
import HomePage from '../Components/HomePage/HomePage'
import Products from '../Components/Products/Products'
import ProductsMain from '../Components/ProductsMain/ProductsMain'
import About from '../Components/About/About'
import SalesMaterial from '../Components/SalesMaterial/SalesMaterial'
import PrivacyPolicy from '../Components/PrivacyPolicy/PrivacyPolicy'
import TermsConditions from '../Components/TermsConditions/TermsConditions'
import Support from '../Components/Support/Support'
import VerifyProducts from '../Components/VerifyProducts/VerifyProducts'
import Wholesale from '../Components/Wholesale/Wholesale'
import NickyJam from '../Components/NickyJam/NickyJam'


export default function Main({navigateTo }) {
    
    return (
        <div>
            <Routes>
                <Route path="/" element={<HomePage navigateTo={navigateTo} />} />
                <Route path="/wholesale" element={<Wholesale  />} />
                <Route path="/contact" element={<Contact  />} />
                <Route path="/about" element={<About  />} />
                <Route path="/products/:product" element={<Products  navigateTo={navigateTo}/>} />
                <Route path="/products" element={<ProductsMain  navigateTo={navigateTo} />} />
                <Route path="/verify" element={<VerifyProducts  navigateTo={navigateTo} />} />
                <Route path="/salesmaterial" element={<SalesMaterial  navigateTo={navigateTo} />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy  navigateTo={navigateTo} />} />
                <Route path="/termsconditions" element={<TermsConditions  navigateTo={navigateTo} />} />
                <Route path="/support" element={<Support  navigateTo={navigateTo} />} />
                
                <Route path="/nickyjam" element={<NickyJam  navigateTo={navigateTo} />} />
            </Routes>
        </div>
    )
}
