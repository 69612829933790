import React, { useState } from 'react'
import { useEffect } from 'react';
import './About.scss'
import { Helmet } from "react-helmet";

import $ from 'jquery';
// images
import AboutBG1 from '../../Images/AboutBG1.png'
import AboutBG2 from '../../Images/AboutBG2.png'
import AboutBG3 from '../../Images/AboutBG3.jpeg'
import AboutBG4 from '../../Images/AboutBG4.jpg'
import LogoBump from '../../Images/LogoBump.png'

export default function About({ navigateTo }) {

    return (
        <div className="About">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>About Us | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/about" data-react-helmet="true" />
                <meta name="description" content="As Bump evolves, our commitment to environmental and societal exploration and preservation remains unwavering. Simultaneously, we persistently delve into eco-friendly materials, aiming for a harmonious coexistence between humanity and nature. Our pursuit of excellence encompasses meticulous attention to detail, ensuring the preservation of the beauty we have encountered." data-react-helmet="true" />
                <meta property="og:title" content="About Us | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="As Bump evolves, our commitment to environmental and societal exploration and preservation remains unwavering. Simultaneously, we persistently delve into eco-friendly materials, aiming for a harmonious coexistence between humanity and nature. Our pursuit of excellence encompasses meticulous attention to detail, ensuring the preservation of the beauty we have encountered." data-react-helmet="true" />
                <meta property="og:site_name" content="About Us | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump about" data-react-helmet="true"></meta>
            </Helmet>


            <div className="AboutFirstSection">
                <img className='AboutFirstSectionBG' src={AboutBG1} alt="" />
                <div className="AboutFirstSectionIfno">
                    {/* <img src={LogoBump} alt="" /> */}
                    <h1>Push the Boundaries to Forge a Path towards a Superior World.</h1>
                </div>
            </div>
            <div className="AboutSecondSection">
                <div className="AboutSecondSectionLeft">
                    <img src={AboutBG3} alt="" />
                </div>
                <div className="AboutSecondSectionRight">
                    <h1>Environmentally Friendly Expeditions</h1>
                    <h2>As Bump evolves, our commitment to environmental and societal exploration and preservation remains unwavering. Simultaneously, we persistently delve into eco-friendly materials, aiming for a harmonious coexistence between humanity and nature. Our pursuit of excellence encompasses meticulous attention to detail, ensuring the preservation of the beauty we have encountered.</h2>
                </div>
            </div>
            <div className="AboutThirdSection">
                <img src={AboutBG4} alt="" />
                <h1>Repetition Breeds Mastery</h1>
            </div>
            <div className="AboutFhorSection">
                <div className="AboutFhorSectionHeader">
                    <h1>Bond of Togetherness</h1>
                    <h2>BUMP: Your Trusted Partner Embarking on a Journey Together with BUMP Vape Unveiling Beauty Through Continuous Exploration Attentive Listening, Creating Extraordinary Experiences Discovering the Beauty in Every Endeavor Exploring Boundless Possibilities Alongside BUMP Building a Lasting Partnership with BUMP Guided by User Feedback, Creating Remarkable Moments Experience the Endless Possibilities with BUMP Embrace the Journey of Discovery with BUMP</h2>
                </div>
                {/* <!-- Photo Grid --> */}
                <div class="container">
                    <div class="gallery">
                        <figure class="gallery__item gallery__item--1">
                            <img src={AboutBG4} alt="Gallery image 1" class="gallery__img" />
                        </figure>
                        <figure class="gallery__item gallery__item--2">
                            <img src={AboutBG4} alt="Gallery image 2" class="gallery__img" />
                        </figure>
                        <figure class="gallery__item gallery__item--3">
                            <img src={AboutBG4} alt="Gallery image 3" class="gallery__img" />
                        </figure>
                        <figure class="gallery__item gallery__item--4">
                            <img src={AboutBG4} alt="Gallery image 4" class="gallery__img" />
                        </figure>
                        <figure class="gallery__item gallery__item--5">
                            <img src={AboutBG4} alt="Gallery image 5" class="gallery__img" />
                        </figure>
                        <figure class="gallery__item gallery__item--6">
                            <img src={AboutBG4} alt="Gallery image 6" class="gallery__img" />
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    )
}

