import React, { useState } from 'react'
import './VerifyProducts.scss'
import { Helmet } from "react-helmet";

import whitesmokess from '../../Images/whitesmokess.webp'
import AboutBG4 from '../../Images/AboutBG4.jpg'
import verifiedImg from '../../Images/verified.png'


export default function VerifyProducts({ navigateTo }) {
    const [verifed, setVerifed] = useState(null);
    const [verifedCode, setVerifedCode] = useState(null);
    const [LoadingBtn, setLoadingBtn] = useState(false);

    const [productsData, setProductsData] = useState([
        {
            productInfo: 'Crazy Bump 6000 Water Melon',
            productCode: '123456'
        }
    ]);

    const VerifacrionFucn = () => {
        setLoadingBtn(true)
        setTimeout(() => {
            if (productsData.find(a => a.productCode == verifedCode)) {
                setVerifed(productsData.find(a => a.productCode == verifedCode))
                console.log(productsData.find(a => a.productCode == verifedCode))
                setLoadingBtn(false)
            } else {
                setVerifed("not")
                console.log('null')
                setLoadingBtn(false)
            }
        }, 2000);
    }

    return (
        <div className="VerifyProducts">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Support | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/support" data-react-helmet="true" />
                <meta name="description" content="Responsive customer service. Knowledgeable support team. Prompt assistance for any inquiries. Troubleshooting guidance. Warranty coverage for eligible issues. User-friendly online resources. Clear and detailed product manuals. Convenient communication channels. Dedicated to ensuring a smooth vaping experience. Commitment to customer satisfaction." data-react-helmet="true" />
                <meta property="og:title" content="Support | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Responsive customer service. Knowledgeable support team. Prompt assistance for any inquiries. Troubleshooting guidance. Warranty coverage for eligible issues. User-friendly online resources. Clear and detailed product manuals. Convenient communication channels. Dedicated to ensuring a smooth vaping experience. Commitment to customer satisfaction." data-react-helmet="true" />
                <meta property="og:site_name" content="Support | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Support" data-react-helmet="true"></meta>
            </Helmet>
            <div className="VerifyProductsMainShadow">
                <div className="VerifyProductsMainShadowColor">
                    <img src={whitesmokess} alt="" />
                </div>
                <div className="VerifyProductsMain">
                    <div className="VerifyProductsMainHeader">
                        <h3>Verification</h3>
                        <img src={verifiedImg} alt="" />
                    </div>
                    <h1>Verify Products</h1>
                    <h2>Locate the authentication label, then scan the QR code or enter your security code to verify.</h2>
                    <div className="VerifyProductsMainInput">
                        <input onChange={(a) => {
                            setVerifedCode(a.target.value.toString())
                        }} placeholder='Enter your security code' type="text" />

                        {!LoadingBtn ?
                            <button onClick={() => {
                                VerifacrionFucn()
                                console.log(verifedCode)
                            }}>Verify</button>
                            :
                            <button class="buttonload">
                                <i class="fa fa-spinner fa-spin"></i>
                            </button>
                        }
                    </div>
                </div>
            </div>
            {verifed && verifed != "not" ?
                <div className="VerifyProductsApprove">
                    <div className="VerifyProductsApproveMain">
                        <img src={verifiedImg} alt="" />
                        <h3 style={{ "color": "green" }}>The security code ({verifedCode}) you provided is verifed.</h3>
                        <h4>{verifed.productInfo}</h4>
                        <button onClick={() => setVerifed(null)}>Done</button>
                    </div>
                </div>
                : verifed == "not" ?
                    <div className="VerifyProductsApprove">
                        <div className="VerifyProductsApproveMain">
                            <img src={verifiedImg} alt="" />
                            <h3 style={{ "color": "red" }}>The security code ({verifedCode}) you provided is not verifed.</h3> 
                            {/* <h4>{verifed.productInfo}</h4> */}
                            <button onClick={() => setVerifed(null)}>Try Again</button>
                        </div>
                    </div>
                    : null
            }

        </div>
    )
}

