import './App.scss';
import Header from '../Tools/Header/Header';
import Footer from '../Tools/Footer/Footer';
import Verify from '../Tools/Verify/Verify';
import Menu from '../Tools/Menu/Menu';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Main from './Main';

function App() {
  const [showMenu, setShowMenu] = useState(false)
  const [showVerify, setShowVerify] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()

  const navigateTo = (page) => {
    navigate('/' + page)
    setShowMenu(false)
  }


  useEffect(() => {
    console.log(location.pathname);
    if (showVerify == true) {
      document.body.style.overflow = "hidden"
      document.body.style.overflow = "scroll"
    } else {

      document.body.style.overflow = "scroll"
    }


  }, [showVerify]);

  return (
    <div className="App" style={false ? { "document.html.style.overflow": "hidden" } : null}>
      {/* {showVerify ? <Verify setShowVerify={setShowVerify} /> : null} */}


      {location.pathname == "/nickyjam" ? console.log("yes") :
        <div className="Warning">
          <h1>WARNING: This product contains nicotine. Nicotine is an addictive chemical.</h1>
        </div>
      }
      {showMenu ? <Menu navigateTo={navigateTo} setShowMenu={setShowMenu} /> : null}
      {location.pathname == "/nickyjam" ? null : <Header navigateTo={navigateTo} setShowMenu={setShowMenu} />}
      <Main navigateTo={navigateTo} />
      {location.pathname == "/nickyjam" ? null : <Footer navigateTo={navigateTo} />}
    </div>
  );
}

export default App;
