import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import Wdistro from '../../Images/1.png'
import LOGO2 from '../../Images/LOGO2.png'
import LogoBump from '../../Images/LogoBump.png'
import tiktok from '../../Images/tiktok.png'
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'

// import BumpWhite from '../../Images/BumpWhite.png'
// import { useLocation } from "react-router-dom";

export default function Footer({ navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="FooterTop">
                <div className="LogoDiv">
                    <div className="LogoDivImgs">
                        <img src={LogoBump} alt="" />
                    </div>
                    {/* <h1>BUMP.US</h1> */}
                    <h2>Make simplicity luxurious</h2>
                </div>
                <div className="SiteMapDiv">
                    <h1 onClick={() => Navigate("/")}>Home</h1>
                    <h2 onClick={() => Navigate("/products")}>Home Page</h2>
                    <h2 onClick={() => Navigate("/products")}>Products</h2>
                    <h2 onClick={() => Navigate("/about")}>About</h2>
                    <h2 onClick={() => Navigate("/support")}>Support</h2>
                    <h2 onClick={() => Navigate("/wholesale")}>Wholesale Request</h2>
                    <h2 onClick={() => Navigate("/contact")}>Contact</h2>
                </div>
                <div className="SiteMapDiv">
                    <h1 onClick={() => Navigate("/products")}>Products</h1>
                    <h2 onClick={() => Navigate("/products/infinity")}>Infinity</h2>
                    <h2 onClick={() => Navigate("/products/genesis")}>Genesis</h2>
                    <h2 onClick={() => Navigate("/products/prox")}>Pro X</h2>
                </div>
                <div className="ContactUs">
                    <h1>Keep In Tuch</h1>
                    <a href="mail:info@northmetalcard.com"><h2>info@bumpvape.com</h2></a>
                </div>
            </div>
            <div className="FooterBottom">
                <div className="FooterBottomTop">
                    <h1>Follow Us</h1>
                    <div className="FooterBottomTopImgs">
                        <img src={instagram} alt="" />
                        <img src={facebook} alt="" />
                        <img src={tiktok} alt="" />
                    </div>
                    <h3>Copyright © 2023 BUMP. All rights reserved. <h4 onClick={() => navigateTo('privacypolicy')}>Privacy Policy</h4><h4 onClick={() => navigateTo('termsconditions')}>Terms & Conditions</h4></h3>
                </div>
                <div className="FooterBottomBottom">
                    <h3>At BUMP, we are dedicated to ensuring responsible practices and preventing the sale of our products to minors. As part of our commitment, we have implemented a rigorous 21+ Age Verification process during checkout, along with an adult (21+) signature confirmation upon delivery for all BUMP purchases.</h3>
                    <h3>Important Notice: California Proposition 65 Warning Please be aware that this product contains chemicals and nicotine, which are known to the State of California to cause cancer, birth defects, or other reproductive harm. To learn more about the potential risks associated with our products, we encourage you to visit www.p65warnings.ca.gov.</h3>
                    <h3>Nicotine Warning: BUMP products containing nicotine e-liquid are not suitable for individuals under the age of 21, pregnant or breastfeeding women, or individuals who are sensitive or allergic to nicotine. Additionally, individuals with or at a risk of an unstable heart condition or high blood pressure should exercise caution when using our products.</h3>
                </div>
            </div>
        </div>
    )
}
