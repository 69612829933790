import React, { useState } from 'react'
import { useEffect } from 'react';
import './ProductsMain.scss'
import $ from 'jquery';
import { Helmet } from "react-helmet";

// images
import bumpVapeSimple1 from '../../Images/6.png'
import bumpVapeSimple2 from '../../Images/5.png'
import bumpVapeSimple3 from '../../Images/7.png'
import watermelon from '../../Images/watermelon.jpeg'
import warning from '../../Images/warning.png'
import FlavorsMains from '../../Images/FlavorsMains.jpeg'
import BGFLAVORS from '../../Images/BGFLAVORS.jpg'
import BGFLAVORS2 from '../../Images/BGFLAVORS2.webp'
import BGFLAVORS3 from '../../Images/BGFLAVORS3.jpeg'
import Arrow2 from '../../Images/Arrow2.svg'


export default function ProductsMain({ navigateTo }) {

    const [Products, setProducts] = useState([
        {
            name: "INFINITY",
            id: "infinity",
            description: "THE COMFORT AND THE FLAVORS",
            h1text: 'ALL DAY LONG',
            h2text: 'As long as possible',
            info: "The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.",
            imageBG: BGFLAVORS,
            imageVape: bumpVapeSimple1,
            h1color: "red",
            url: "products/infinity"
        },
        {
            name: "GENESIS",
            id: "genesis",
            description: "THE COMFORT AND THE FLAVORS",
            h1text: 'CONTRACT...',
            h2text: 'But Compact',
            info: "The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.",
            imageBG: BGFLAVORS2,
            imageVape: bumpVapeSimple2,
            h1color: "yellow",
            url: "products/genesis"
        },
        {
            name: "PRO X",
            id: "prox",
            description: "THE COMFORT AND THE FLAVORS",
            h1text: 'AWW !!',
            h2text: 'Fresh',
            info: "The Bump EXTRA is our original 1500 puff salt nicotine disposable vape that comes in a variety of incredible flavors to choose from. This device is great for beginners looking to switch to a vape that doesn’t require any filling or charging. Whether you’re a beginner looking to quit traditional cigarettes or you’re an experienced user wanting maximum puffs, this is the perfect vape to complement your lifestyle. It’s also great for traveling and for those wanting a more discrete vaping experience. Sometimes the original is all you need for everyday puffing.",
            imageBG: BGFLAVORS3,
            imageVape: bumpVapeSimple3,
            h1color: "white",
            url: "products/prox"
        }
    ]);


    return (
        <div className="ProductsMain">
            <Helmet>
                {/* <!-- TITLE --> */}
                <title>Discover our vaping products | The Bump Vape</title>
                {/* <!-- META --> */}
                <meta property="og:locale" content="en_US" data-react-helmet="true" />
                <meta property="og:type" content="article" data-react-helmet="true" />
                <meta property="og:image" content="article" data-react-helmet="true" />
                <meta property="og:url" content="https://www.thebumpvape.com/products" data-react-helmet="true" />
                <meta name="description" content="Innovative, sleek design. Wide range of flavors. Compact and portable. Premium-quality vapor production. Long-lasting battery life. Easy-to-use functionality. Satisfying nicotine hit. Stylish and trendy. Diverse nicotine strength options. Satisfaction guaranteed." data-react-helmet="true" />
                <meta property="og:title" content="Discover our vaping products | The Bump Vape" data-react-helmet="true" />
                <meta property="og:description" content="Innovative, sleek design. Wide range of flavors. Compact and portable. Premium-quality vapor production. Long-lasting battery life. Easy-to-use functionality. Satisfying nicotine hit. Stylish and trendy. Diverse nicotine strength options. Satisfaction guaranteed." data-react-helmet="true" />
                <meta property="og:site_name" content="Discover our vaping products | The Bump Vape" data-react-helmet="true" />
                <meta name="keywords" content="BUMP, electronic cigarette, e-cigarette, vaping product, vapes, vape bump, bump Products" data-react-helmet="true"></meta>
            </Helmet>

            {Products.map((A, index) =>
                <div className="ProductsTop">
                    {console.log(A)}
                    <img className='ProductsTopBG' src={A.imageBG} alt="" />
                    <img className='ProductsTopVape' src={A.imageVape} alt="" />
                    <div className="ProductsTopInfo">
                        <h1 style={{ "color": A.h1color }}>{A.h1text}</h1>
                        <h2 style={{ "color": A.h1color, "backdropFilter": "blur(10px)", "backgroundColor": "#ffffff22" }}>{A.h2text}</h2>
                        <button onClick={() => navigateTo(A.url)}>Learn More</button>
                    </div>
                </div>
            )}

        </div>
    )
}

